import React, { Component } from 'react'

export default class SubscribeForm extends Component {
  render() {
    return (
      <div>
        <iframe
          width="100%"
          height="200"
          src="https://csspurge.substack.com/embed"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    )
  }
}
